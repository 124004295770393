<template>
    <div>
        <div class="loading">
            <div class="loader-ring">
                <div class="loader-ring-light"></div>
                <div class="loader-ring-track"><img style="margin: 0 auto" src="../img/logo2.png" width="240px" height="230px"></div>

            </div>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "wxLogin",
        data(){
            return {
                code:''
            }
        },
        methods:{
            ...mapActions('login',['wxLogin'])
        },
        mounted() {
            this.code = this.$route.query.code
            console.log(this.code)

            this.wxLogin(this.code).then(success => {
                if (success.ret == 0) {
                    this.$cookies.set('token', success.data.access_token)
                    this.$cookies.set('isLogin', true)
                    this.$router.push('/work')
                }
            })

        }
    }
</script>

<style>

    .loader-ring {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -120px 0 0 -120px;
        width: 240px;
        height: 240px;
    }

    .loader-ring-light {
        width: 240px;
        height: 240px;
        -moz-border-radius: 240px;
        -webkit-border-radius: 240px;
        border-radius: 240px;
        -moz-box-shadow: 0 4px 0 #00abf2 inset;
        -webkit-box-shadow: 0 4px 0 #00abf2 inset;
        box-shadow: 0 4px 0 #00abf2 inset;
        animation: rotate-360 2s linear infinite;
    }

    .loader-ring-track {
        position: absolute;
        top: 0;
        left: 0;
        width: 240px;
        height: 240px;
        -moz-border-radius: 240px;
        -webkit-border-radius: 240px;
        border-radius: 240px;
        -moz-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3) inset;
        -webkit-box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3) inset;
        box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.3) inset;
    }

    @keyframes rotate-360 {
        from {
            -moz-transform: rotate(0);
            -ms-transform: rotate(0);
            -webkit-transform: rotate(0);
            transform: rotate(0);
        }
        to {
            -moz-transform: rotate(360deg);
            -ms-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
</style>